.MuiButton {
  border: 1px #e5e0e0;
}

.rpv-core__inner-pages::-webkit-scrollbar {
  width: 17px;
}

.rpv-core__inner-pages::-webkit-scrollbar-track {
  background: #d9d9d9cc;
  border: 0.5px solid #00203d;
}

.rpv-core__inner-pages::-webkit-scrollbar-thumb {
  background: #00203d;
  border-radius: none;
}

.rpv-core__inner-pages {
  scrollbar-width: thick;
  scrollbar-color: #00203d;
}
.rpv-core__page-layer {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.rpv-core__canvas-layer {
  width: 100% !important;
}
.ag-theme-quartz {
  --ag-header-height: 30px;
  --ag-header-background-color: #00203d;
  --ag-header-cell-hover-background-color: #00203d;
}
.ag-theme-quartz .ag-header {
  background-color: #00203d;
}

.ag-theme-quartz .ag-header-cell {
  color: #fff;
}
.ag-theme-quartz .ag-header-icon.ag-header-cell-filter-button .ag-icon-filter {
  fill: white;
  color: white;
}

.ag-theme-quartz .ag-sort-ascending-icon .ag-icon-asc,
.ag-theme-quartz .ag-sort-descending-icon .ag-icon-desc {
  fill: white;
  color: white;
}
.ag-root-wrapper,
.ag-paging-panel {
  border: none !important;
  border-radius: 0 !important;
}

.ag-center-cols-viewport {
  min-height: unset !important;
}
