.login-error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f3f4f6;
}

.login-error-box {
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.login-error-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #dc2626;
  margin-bottom: 1rem;
}

.login-error-message {
  color: #4b5563;
  margin-bottom: 1.5rem;
}

.try-again-button {
  background-color: #3b82f6;
  color: white;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
}

.try-again-button:hover {
  background-color: #2563eb;
}

.separator {
  margin: 0 0.5rem;
}
