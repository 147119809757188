.highlight {
  background-color: rgb(22, 190, 22);
  display: inline;
  vertical-align: baseline;
  border: 2px solid black;
}
.results-container {
  padding: 20px;
}

.information-container {
  margin-bottom: 20px;
}

.pdf-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 30px auto;
  width: 100%;

  border: 2px solid #2f94cd;
  padding-bottom: 70px;
}

.pdf-viewer-container {
  flex: 1;
  height: 40vh;
}

.pdf-viewer-container h3 {
  text-align: center;
  margin-bottom: 10px;
}

.rpv-core__annotation-popup-wrapper {
  display: none !important;
}
